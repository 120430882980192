import { IonContent, IonPage, IonInput, useIonRouter, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useState } from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageContainer from "../../components/PageContainer/PageContainer";
import PageItemHeading from "../../components/PageItemHeading/PageItemHeading";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";
import { useMutation, gql } from "@apollo/client";
import DawaAutocomplete2 from "../../components/dawa-autocomplete2";
import "./CustomerInfo.css";
import { Countries } from "./Countries";

const UPDATE_CUSTOMERINFO = gql`
  mutation updateCustomer($input: updateAmlCustomerInput!) {
    updateCustomer(input: $input)
  }
`;

//toDO:

//{foCountriesods.map((country) => (
//  <IonSelectOption key={food.id} value={food}>
//    {food.name}
//  </IonSelectOption>
//))}

const PersonInfo: React.FC = () => {
  const [updateCustomerInfo, { loading, error, data }] = useMutation(UPDATE_CUSTOMERINFO);
  const [validate, setValidate] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<string | null>(null);
  const [customerAddressCountry, setcustomerAddressCountry] = useState<string>(datastore.data.customerInfo.currentAddressCountry);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const router = useIonRouter();

  if (loading) {
    //console.log("Loading");
    setTimeout(() => {
      setValidate(false);
      router.push("/home/" + datastore.data.id, "back", "push");
    }, 500);
  }
  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved updatering af kunde data. " + error.message;
  }
  if (data) {
    datastore.data.customerInfo.formFilled = true;
  }

  const updateField = (fieldName: any, fieldValue: any) => {
    (datastore.data.customerInfo as any)[fieldName] = fieldValue;

    //console.log(datastore.data.customerInfo);
    if (fieldName === "isCompany") {
      //console.log("Forceupdate -> isCompany");
      forceUpdate();
    }
  };

  const submitClicked = () => {
    setValidate(true);

    let isvalid: boolean;
    isvalid = true;
    for (var key of Object.keys(datastore.data.customerInfo)) {
      let fieldValue = (datastore.data.customerInfo as any)[key];

      //DK
      //company
      if (datastore.data.customerInfo.isCompany && key !== "lastName" && key !== "birthDate" && key !== "citizenship" && key !== "currentAddressDoor" && key !== "currentAddressFloor" && datastore.data.customerInfo.currentAddressCountry === "DK") {
        //console.log("datastore.data.customerInfo.isCompany:" + datastore.data.customerInfo.isCompany + " - key:" + key + " - value:" + fieldValue);
        if (fieldValue === "") {
          isvalid = false;
        }
      }
      if (!datastore.data.customerInfo.isCompany && key !== "ssn" && key !== "currentAddressDoor" && key !== "currentAddressFloor" && datastore.data.customerInfo.currentAddressCountry === "DK") {
        //console.log("datastore.data.customerInfo.isCompany:" + datastore.data.customerInfo.isCompany + " - key:" + key + " - value:" + fieldValue);
        if (fieldValue === "") {
          isvalid = false;
        }
      }

      //FOURENT COUNTRY
      let ignorKeys = ["currentAddressRoadnameAndNumber", "currentAddressZipcode", "currentAddressCity", "currentAddressRoadCode", "currentAddressRoadName", "currentAddressHouseNumber", "currentAddressFloor", "currentAddressDoor"];
      if (datastore.data.customerInfo.isCompany && key !== "lastName" && key !== "birthDate" && key !== "citizenship" && datastore.data.customerInfo.currentAddressCountry !== "DK" && !ignorKeys.includes(key)) {
        //console.log("datastore.data.customerInfo.isCompany:" + datastore.data.customerInfo.isCompany + " - key:" + key + " - value:" + fieldValue);
        if (fieldValue === "") {
          isvalid = false;
        }
      }
      if (!datastore.data.customerInfo.isCompany && key !== "ssn" && datastore.data.customerInfo.currentAddressCountry !== "DK" && !ignorKeys.includes(key)) {
        //console.log("datastore.data.customerInfo.isCompany:" + datastore.data.customerInfo.isCompany + " - key:" + key + " - value:" + fieldValue);
        if (fieldValue === "") {
          isvalid = false;
        }
      }
    }

    if (isvalid) {
      if (datastore.data.customerInfo.isCompany) {
        datastore.data.customerInfo.lastName = "";
        datastore.data.customerInfo.birthDate = "";
        datastore.data.customerInfo.citizenship = "";
      } else {
        datastore.data.customerInfo.ssn = "";
      }

      //console.log("Update backend");
      updateCustomerInfo({
        variables: {
          input: {
            id: datastore.data.customerInfo.id,
            firstName: datastore.data.customerInfo.firstName,
            lastName: datastore.data.customerInfo.lastName,
            currentAddressCountry: datastore.data.customerInfo.currentAddressCountry,
            currentAddressFull: datastore.data.customerInfo.currentAddressFull,
            currentAddressRoadnameAndNumber: datastore.data.customerInfo.currentAddressRoadnameAndNumber,
            currentAddressZipCode: datastore.data.customerInfo.currentAddressZipcode,
            currentAddressCity: datastore.data.customerInfo.currentAddressCity,
            currentAddressRoadCode: datastore.data.customerInfo.currentAddressRoadCode,
            currentAddressRoadName: datastore.data.customerInfo.currentAddressRoadName,
            currentAddressHouseNumber: datastore.data.customerInfo.currentAddressHouseNumber,
            currentAddressDoor: datastore.data.customerInfo.currentAddressDoor,
            currentAddressFloor: datastore.data.customerInfo.currentAddressFloor,
            phone: datastore.data.customerInfo.phone,
            email: datastore.data.customerInfo.email,
            birthDate: datastore.data.customerInfo.birthDate,
            citizenship: datastore.data.customerInfo.citizenship,
            planToLiveInEstate: datastore.data.customerInfo.planToLiveInEstate,
            isCompany: datastore.data.customerInfo.isCompany,
            ssn: datastore.data.customerInfo.ssn,
          },
        },
      });
    } else {
      //console.log("Forceupdate");

      forceUpdate();
    }
  };

  const countryChanged = (countryCode: string) => {
    datastore.data.customerInfo.currentAddressCountry = countryCode;
    setcustomerAddressCountry(countryCode);
  };

  const setForeignCountryAddress = (addressString: string | null | undefined) => {
    if (addressString) {
      datastore.data.customerInfo.currentAddressFull = addressString;
    } else {
      datastore.data.customerInfo.currentAddressFull = "";
    }
    datastore.data.customerInfo.currentAddressRoadnameAndNumber = "";
    datastore.data.customerInfo.currentAddressZipcode = "";
    datastore.data.customerInfo.currentAddressCity = "";
    datastore.data.customerInfo.currentAddressRoadCode = 0;
    datastore.data.customerInfo.currentAddressRoadName = "";
    datastore.data.customerInfo.currentAddressHouseNumber = "";
    datastore.data.customerInfo.currentAddressDoor = "";
    datastore.data.customerInfo.currentAddressFloor = "";
  };

  const setAddress = (addressInfo: any) => {
    //console.log("addressInfo:", addressInfo);
    datastore.data.customerInfo.currentAddressCountry = "DK"; // addressInfo
    datastore.data.customerInfo.currentAddressFull = addressInfo.tekst;
    datastore.data.customerInfo.currentAddressRoadnameAndNumber = addressInfo.tekst; // addressInfo
    datastore.data.customerInfo.currentAddressZipcode = addressInfo.data.postnr;
    datastore.data.customerInfo.currentAddressCity = addressInfo.data.postnrnavn;
    datastore.data.customerInfo.currentAddressRoadCode = parseInt(addressInfo.data.vejkode);
    datastore.data.customerInfo.currentAddressRoadName = addressInfo.data.vejnavn;
    datastore.data.customerInfo.currentAddressHouseNumber = addressInfo.data.husnr;

    if (addressInfo.data.dør) {
      datastore.data.customerInfo.currentAddressDoor = addressInfo.data.dør;
    } else {
      datastore.data.customerInfo.currentAddressDoor = "";
    }

    if (addressInfo.data.etage) {
      datastore.data.customerInfo.currentAddressFloor = addressInfo.data.etage;
    } else {
      datastore.data.customerInfo.currentAddressFloor = "";
    }

    //console.log("datastore:", datastore);
  };

  const cancelClicked = () => {
    if (customerData) {
      datastore.data.customerInfo = JSON.parse(customerData);
    }

    router.goBack();
  };

  if (!customerData) {
    const customerData = JSON.stringify(datastore.data.customerInfo);
    setCustomerData(customerData);
  }

  let dawaFieldClass = validate && datastore.data.customerInfo.currentAddressRoadnameAndNumber === "" ? "text-field-dawa-error" : "text-field-dawa";

  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <PageHeading itemHeading="Personoplysninger" itemText="Udfyld oplysninger om dig og evt. fuldmagt." itemNo={datastore.data.currentItemNo} />
          <div>
            <PageItemHeading>Fornavn</PageItemHeading>
            <IonInput
              autocomplete="given-name"
              value={datastore.data.customerInfo.firstName}
              inputmode="text"
              className={validate && datastore.data.customerInfo.firstName === "" ? "text-field-error" : "text-field"}
              onIonChange={(e) => updateField("firstName", (e.target as HTMLIonInputElement).value)}
            ></IonInput>
            <PageItemHeading>Efternavn</PageItemHeading>
            <IonInput
              onIonChange={(e) => updateField("lastName", (e.target as HTMLIonInputElement).value)}
              autocomplete="additional-name"
              inputmode="text"
              value={datastore.data.customerInfo.lastName}
              className={validate && datastore.data.customerInfo.lastName === "" ? "text-field-error" : "text-field"}
              required
            ></IonInput>
          </div>
          <PageItemHeading>Nuværende adresse land</PageItemHeading>
          <IonSelect interface="popover" aria-label="Nuværende adresse land" slot="end" value={datastore.data.customerInfo.currentAddressCountry} onIonChange={(ev) => countryChanged(ev.detail.value)}>
          {Countries.map((country) => (
  <IonSelectOption key={country.value} value={country.value}>
    {country.text}
  </IonSelectOption>
))}

           
          
          </IonSelect>
          <PageItemHeading>Nuværende adresse</PageItemHeading>
          {customerAddressCountry === "DK" ? (
            <>
              {datastore.data.customerInfo.currentAddressRoadnameAndNumber ? (
                <DawaAutocomplete2
                  select={(selected) => {
                    setAddress(selected);
                  }}
                  inputValue={datastore.data.customerInfo.currentAddressRoadnameAndNumber}
                  classes={{
                    root: "autocomplete-dawa-container",
                    input: dawaFieldClass,
                    ul: "ul",
                    li: "li",
                    selected: "selected",
                  }}
                />
              ) : (
                <DawaAutocomplete2
                  select={(selected) => {
                    setAddress(selected);
                  }}
                  classes={{
                    root: "autocomplete-dawa-container",
                    input: dawaFieldClass,
                    ul: "ul",
                    li: "li",
                    selected: "selected",
                  }}
                />
              )}
            </>
          ) : (
            <>
              <IonInput
                onIonChange={(e) => setForeignCountryAddress((e.target as HTMLIonInputElement).value + "")}
                autocomplete="street-address"
                inputmode="text"
                value={datastore.data.customerInfo.currentAddressFull}
                className={validate && datastore.data.customerInfo.currentAddressFull === "" ? "text-field-error" : "text-field"}
                required
              ></IonInput>
            </>
          )}
          <PageItemHeading>E-mail</PageItemHeading>
          <IonInput
            onIonChange={(e) => updateField("email", (e.target as HTMLIonInputElement).value)}
            autocomplete="email"
            inputmode="email"
            value={datastore.data.customerInfo.email}
            className={validate && datastore.data.customerInfo.email === "" ? "text-field-error" : "text-field"}
            required
          ></IonInput>
          <PageItemHeading>Telefon</PageItemHeading>
          <IonInput
            onIonChange={(e) => updateField("phone", (e.target as HTMLIonInputElement).value)}
            autocomplete="tel"
            inputmode="tel"
            maxlength={8}
            value={datastore.data.customerInfo.phone}
            className={validate && datastore.data.customerInfo.phone === "" ? "text-field-error" : "text-field"}
            required
          ></IonInput>
          <div>
            <PageItemHeading>Fødselsdato</PageItemHeading>
            <IonInput
              onIonChange={(e) => updateField("birthDate", (e.target as HTMLIonInputElement).value)}
              autocomplete="off"
              type="date"
              value={datastore.data.customerInfo.birthDate}
              className={validate && datastore.data.customerInfo.birthDate === "" ? "text-field-error" : "text-field"}
              required
            ></IonInput>
            <PageItemHeading>Statsborgerskab</PageItemHeading>
            <IonInput
              onIonChange={(e) => updateField("citizenship", (e.target as HTMLIonInputElement).value)}
              autocomplete="country-name"
              inputmode="text"
              value={datastore.data.customerInfo.citizenship}
              className={validate && datastore.data.customerInfo.citizenship === "" ? "text-field-error" : "text-field"}
              required
            ></IonInput>
          </div>
          {datastore.data.customerInfo.type === "buyer" ? (
            <div>
              <PageItemHeading>Skal du selv bo på adressen?</PageItemHeading>
              <div className="radioContainer">
                <div className="floatLeft">
                  <input type="radio" name="radio" value="true" aria-label="" onClick={(e) => updateField("planToLiveInEstate", true)} defaultChecked={datastore.data.customerInfo.planToLiveInEstate} />
                </div>
                <div className="floatLeftRadioText">Ja</div>
                <div className="floatLeft">
                  <input type="radio" name="radio" value="false" aria-label="" onClick={(e) => updateField("planToLiveInEstate", false)} defaultChecked={!datastore.data.customerInfo.planToLiveInEstate} />
                </div>
                <div className="floatLeftRadioText">Nej</div>
              </div>
            </div>
          ) : (
            ""
          )}
          <br></br>
          <br></br> <br></br> <br></br>
          <div className="buttonGroup">
            <Button type="normal" onClick={cancelClicked} text="Annuller" />
            <Button type="highlight" onClick={submitClicked} text="Godkend" />
          </div>
          <br></br>
          <br></br>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default PersonInfo;

/*
 <PageItemHeading>Nuværende adresse</PageItemHeading>
          <IonInput
            onIonChange={(e) => updateField("currentAddressRoadnameAndNumber", (e.target as HTMLInputElement).value)}
            autocomplete="street-address"
            inputmode="text"
            value={datastore.data.customerInfo.currentAddressRoadnameAndNumber}
            className={validate && datastore.data.customerInfo.currentAddressRoadnameAndNumber === "" ? "text-field-error" : "text-field"}
            required
          ></IonInput>
          <PageItemHeading>Postnr.</PageItemHeading>
          <IonInput
            onIonChange={(e) => updateField("currentAddressZipcode", (e.target as HTMLInputElement).value)}
            autocomplete="postal-code"
            inputmode="numeric"
            value={datastore.data.customerInfo.currentAddressZipcode}
            className={validate && datastore.data.customerInfo.currentAddressZipcode === "" ? "text-field-error" : "text-field"}
            required
          ></IonInput>
          <PageItemHeading>By</PageItemHeading>
          <IonInput
            onIonChange={(e) => updateField("currentAddressCity", (e.target as HTMLInputElement).value)}
            autocomplete="address-line2"
            name="city"
            inputmode="text"
            value={datastore.data.customerInfo.currentAddressCity}
            className={validate && datastore.data.customerInfo.currentAddressCity === "" ? "text-field-error" : "text-field"}
            required
          ></IonInput>
          */
