import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import "./start.css";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";

const Start: React.FC = () => {
  const router = useIonRouter();

  const startFlow = () => {
    router.push("/home/" + datastore.data.id, "back", "push");
  };

  return (
    <IonPage>
      <IonContent>
        <div className="colorOverlay"></div>
        <div className="backGroundImage"></div>
        <div className="startPageContainer">
          <div className="innerStartPageContainer">
            <div className="container">
              <div className="logo"></div>
              <div className="adress">
                {datastore.data.estateCase.caseType === "Counseling" ? (
                  <>
                  <div className="vedr">Vedr. din køberrådgivningssag</div>
                  </>
                ) : (
                  <>
                    {datastore.data.customerInfo.type === "seller" ? (
                      <div className="vedr">Vedr. salg af</div>
                    ) : (
                      <div className="vedr">Vedr. køb af</div>
                    )}
                  </>
                )}
                <div className="roadAndNumer">{datastore.data.estateCase.roadAndNumber}</div>
                <div className="cityAndZip">{datastore.data.estateCase.zipCodeAndCity}</div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <Button type="highlight" onClick={startFlow} text="Udfyld skema" />
            </div>
          </div>
        </div>
        <div className="bottomText">Digital oplysningsskema</div>
      </IonContent>
    </IonPage>
  );
};

export default Start;
