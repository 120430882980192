
import "./PageItemHeading.css";

interface Props {
}

const PageItemHeading: React.FC<Props> = (props) => {
  return (
    <div className="pageItemHeading">{props.children}</div>
  );
};

export default PageItemHeading;
