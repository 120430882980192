import "./MainItemContainer.css";

interface Props {

}

const MainItemContainer: React.FC<Props> = (props) => {
  return (
    <div className="ItemContainer">
      <div className="innerItemContainer">{props.children}</div>
    </div>
  );
};

export default MainItemContainer;
