import {  Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Done from "./pages/Done/Done";
import CustomerInfo from "./pages/CustomerInfo/CustomerInfo";
import Identification from "./pages/Identification/Identification";
import IdentificationInfo from "./pages/Identification/IdentificationInfo";
import MoneyLaunderingInfo from "./pages/MoneyLaundering/MoneyLaunderingInfo";
import MoneyLaunderingQuestions from "./pages/MoneyLaundering/MoneyLaunderingQuestions";
import Pep from "./pages/MoneyLaundering/Pep";
import PepBusinessPartner from "./pages/MoneyLaundering/PepBusinessPartner";
import PepCloseRelation from "./pages/MoneyLaundering/PepCloseRelation";
//import Consents from "./pages/Consents/Consents";
import ConsentsEdit from "./pages/Consents/ConsentsEdit";
import ApproveInfo from "./pages/Approve/ApproveInfo";
import Start from  "./pages/Start/start";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

//<Redirect exact from="/" to="/start" />
//<Route exact path="/samtykker/:id1" component={Consents} />
                

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet mode="ios">
          <Route exact path="/aml/:id1" component={Start} />
          <Route exact path="/home/:id1" component={Home} />
          <Route exact path="/done/:id1" component={Done} />
          <Route exact path="/person/:id1" component={CustomerInfo} />
          <Route exact path="/legitimationinfo/:id1" component={IdentificationInfo} />
          <Route exact path="/legitimation/:id1" component={Identification} />
          <Route exact path="/hvidvask/:id1" component={MoneyLaunderingInfo} />
          <Route path="/hvidvask/sp/:id1" component={MoneyLaunderingQuestions} />
          <Route path="/hvidvask/pep/:id1" component={Pep} />
          <Route exact path="/hvidvask/pep_familie/:id1" component={PepBusinessPartner} />
          <Route exact path="/hvidvask/pep_relation/:id1" component={PepCloseRelation} />
          <Route exact path="/samtykker/:id1" component={ConsentsEdit} />
          <Route exact path="/godkend/:id1" component={ApproveInfo} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
