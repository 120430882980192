import "./PageHeading.css";

interface Props {
  itemNo: string;
  itemHeading: string;
  itemText: string;
}

const PageHeading: React.FC<Props> = (props) => {

  return (
    <div>
        <div className="pageHeadingItem">
          <div className="circle">{props.itemNo}</div>
          <div className="heading">{props.itemHeading}</div>
          <div className="text" dangerouslySetInnerHTML={{ __html: props.itemText }}></div>
        </div>
    </div>
  );
};

export default PageHeading;
