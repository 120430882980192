import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";

const MoneyLaunderingInfo: React.FC = () => {
  const router = useIonRouter();

  const acceptedClicked = () => {
    router.push("/legitimation/" + datastore.data.id, "forward", "push");
  };

  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>

        <h2>Oplysninger omkring legitimation</h2>

        <p>For at overholde vores forpligtigelser i henhold til hvidvaskloven er det vigtigt at vi modtager din legitimation.</p>
        <p>Du skal uploade dit:</p>
        <ul>
            <li>sundhedskort og</li>
            <li>kørekort eller pas.</li>
        </ul>

        <p>For at legitimationen er gyldig, skal du være opmærksom på følgende:</p>

        <ul>
            <li>Uploader du en kopi af dit elektroniske kørekort, så husk begge sider.</li>
            <li>Uploader du en kopi af dit elektroniske sundhedskort, skal du huske at trykke “vis CPR” inden du tager et skærmprint.</li>
        </ul>
           <br></br>
          <br></br> <br></br> <br></br>
          <div className="buttonGroup">
            <Button type="highlight" onClick={acceptedClicked} text="OK - Gå videre" />
          </div>
          <br></br>
          <br></br>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default MoneyLaunderingInfo;
