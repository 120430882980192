import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";

const PepBusinessPartner: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <h2>En nær samarbejds-partner til en politisk eksponeret person er:</h2>
          <ul>
            <li>Fysisk person, som er reel ejer af en virksomhed eller anden form for juridisk person i fællesskab med en eller flere PEP’er (mere end 25 % ejerskab er en indikator for at blive betragtet som reel ejer)</li>
            <li>Fysisk person, der på anden måde har en nær forretningsforbindelse med en eller flere PEP’er. (f.eks. en samhandelspartner over en længere periode)</li>
            <li>Fysisk person, som er den eneste reelle ejer af en virksomhed eller anden form for juridisk person, der udelukkende er oprettet til fordel for en PEP.</li>
          </ul>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default PepBusinessPartner;
