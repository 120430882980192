import "./PageContainer.css";

interface Props {

}

const PageContainer: React.FC<Props> = (props) => {
  return (
    <div className="PageContainer">
      <div className="innerPageContainer">{props.children}</div>
    </div>
  );
};

export default PageContainer;
