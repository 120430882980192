import { useIonRouter } from "@ionic/react";
import "./MainItem.css";
import { datastore } from "../../dataStore";

interface Props {
  itemNo: string;
  itemHeading: string;
  itemText: string;
  href: string;
  isLast: boolean;
  isChecked: boolean;
  highlight: boolean;
}

const MainItem: React.FC<Props> = (props) => {
  const router = useIonRouter();

  const navigate = () => {
    if (props.href !== "") {

      datastore.data.currentItemNo = props.itemNo;
      router.push(props.href + "/" + datastore.data.id, "forward", "push");
    }
  };

  let itemClass = "item";
  if (props.isLast) {
    itemClass = "itemLast";
  }
  if (props.highlight) {
    itemClass = itemClass + " itemHighlight";
  }

  return (
    <div>
      <div className={itemClass} onClick={navigate}>
        {props.isChecked ? <div className="circleChecked">
          <svg xmlns="http://www.w3.org/2000/svg" className="checkmark" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96"/></svg>
        </div> : <div className="circle">{props.itemNo}</div>}
        <div className="heading">{props.itemHeading}</div>
        <div className="text">{props.itemText}</div>
      </div>

      {!props.isLast && (
        <div className="spacer">
          <div className="line"></div>
        </div>
      )}
    </div>
  );
};

export default MainItem;
