import "./TopContainer.css";

interface Props {
  roadAndNumer?: string;
  cityAndZip?: string;
}

const TopContainer: React.FC<Props> = (props) => {
  return (
    <div className="topContainer">
      <div className="logo"></div>
      <div className="adress">
        <div className="roadAndNumer">{props.roadAndNumer}</div>
        <div className="cityAndZip">{props.cityAndZip}</div>
      </div>
    </div>
  );
};

export default TopContainer;
