import { IonContent, IonHeader, IonPage, useIonRouter } from "@ionic/react";
import MainItemContainer from "../components/MainItemContainer/MainItemContainer";
import TopContainer from "../components/TopContainer/TopContainer";
import MainItem from "../components/MainItem/MainItem";
import { datastore } from "../dataStore";
import React, { useState } from "react";
import "./Home.css";
//import { useMutation, gql } from "@apollo/client";

/*
const COMPLEATE_AML = gql`
  mutation completeAml($id: ID!) {
    completeAml(id: $id) {
      status
      signUrl
    }
  }
`;
*/

const Home: React.FC = () => {
  const router = useIonRouter();
  //const [completeAml, { loading, error, data }] = useMutation(COMPLEATE_AML);
  const [validate, setValidate] = useState<boolean>(false);

  /*
  if (loading) return <p></p>; //loading

  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved færdig gørelse af AML flow. " + error.message;
  }
  if (data) {
    window.location.href = data.completeAml.signUrl;
  }
  */

  const signDocClickedWithPEP = () => {
    console.log("signDocClicked - datastore:", datastore.data);
    if (
      datastore.data.customerInfo.formFilled &&
      (datastore.data.identification.formFilled || !datastore.data.identification.enabled)  &&
      datastore.data.moneyLaundering.formFilled &&
      datastore.data.personConsents.formFilled
    ) {
      router.push("/godkend/" + datastore.data.id, "forward", "push");
    } else {
      setValidate(true);
    }
  };

  const signDocClickedWithoutPEP = () => {
    console.log("signDocClicked - datastore:", datastore.data);
    if (
      datastore.data.customerInfo.formFilled &&
      (datastore.data.identification.formFilled || !datastore.data.identification.enabled) &&
      datastore.data.personConsents.formFilled
    ) {
      router.push("/godkend/" + datastore.data.id, "forward", "push");
    } else {
      setValidate(true);
    }
  };

  
  let itemNo = 0;

  const nextItemNo = () => {
    itemNo = itemNo+1;
    return "0" + itemNo;
  } 

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent fullscreen>
        <TopContainer
          roadAndNumer={datastore.data.estateCase.roadAndNumber}
          cityAndZip={datastore.data.estateCase.zipCodeAndCity}
        ></TopContainer>

        {datastore.data.moneyLaundering.politicallyExposedPersonSelected ? (
          <MainItemContainer>
            <MainItem
              highlight={
                validate && !datastore.data.customerInfo.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.customerInfo.formFilled}
              itemHeading="Personoplysninger"
              itemText="Udfyld oplysninger om dig og evt. fuldmagt."
              itemNo={nextItemNo()}
              href="/person"
              isLast={false}
            />
            <MainItem
              highlight={
                validate && !datastore.data.moneyLaundering.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.moneyLaundering.formFilled}
              itemHeading="Hvidvask"
              itemText="Kundekenskabsprocedure."
              itemNo={nextItemNo()}
              href="/hvidvask"
              isLast={false}
            />
            {datastore.data.identification.enabled && (
            <MainItem
              highlight={
                validate && !datastore.data.identification.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.identification.formFilled}
              itemHeading="Legitimation"
              itemText="Tag et billede af både dit sundhedskort og kørekort eller pas."
              itemNo={nextItemNo()}
              href="/legitimationinfo"
              isLast={false}
            />
            )}
            <MainItem
              highlight={
                validate && !datastore.data.personConsents.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.personConsents.formFilled}
              itemHeading="Samtykker"
              itemText="Samtykke til behandling og videregivelse af personoplysninger."
              itemNo={nextItemNo()}
              href="/samtykker"
              isLast={false}
            />
            <div onClick={signDocClickedWithPEP}>
              <MainItem
                highlight={false}
                isChecked={false}
                itemHeading="Godkend oplysninger"
                itemText="Læs og godkend med digital signatur."
                itemNo={nextItemNo()}
                href=""
                isLast={true}
              />
            </div>
          </MainItemContainer>
        ) : (
          <MainItemContainer>
            <MainItem
              highlight={
                validate && !datastore.data.customerInfo.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.customerInfo.formFilled}
              itemHeading="Personoplysninger"
              itemText="Udfyld oplysninger om dig og evt. fuldmagt."
              itemNo={nextItemNo()}
              href="/person"
              isLast={false}
            />
            <MainItem
              highlight={
                validate && !datastore.data.identification.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.identification.formFilled}
              itemHeading="Legitimation"
              itemText="Tag et billede af både dit sundhedskort og kørekort eller pas."
              itemNo={nextItemNo()}
              href="/legitimationinfo"
              isLast={false}
            />
            <MainItem
              highlight={
                validate && !datastore.data.personConsents.formFilled
                  ? true
                  : false
              }
              isChecked={datastore.data.personConsents.formFilled}
              itemHeading="Samtykker"
              itemText="Samtykke til behandling og videregivelse af personoplysninger."
              itemNo={nextItemNo()}
              href="/samtykker"
              isLast={false}
            />
            <div onClick={signDocClickedWithoutPEP}>
              <MainItem
                highlight={false}
                isChecked={false}
                itemHeading="Godkend oplysninger"
                itemText="Læs og godkend med digital signatur."
                itemNo={nextItemNo()}
                href=""
                isLast={true}
              />
            </div>
          </MainItemContainer>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;
