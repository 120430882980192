import { IonContent, IonPage, useIonRouter, IonTextarea } from "@ionic/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";
import { useMutation, gql } from "@apollo/client";

const UPDATE_MONEYLAUNDERING = gql`
    mutation updateCustomer($input: updateAmlCustomerInput!) {
      updateCustomer(input: $input)
    }
`;

const MoneyLaunderingQuestions: React.FC = () => {
  const [moneyLaunderingQuestionsData, setMoneyLaunderingQuestionsData] = useState<string | null>(null);
  const [highlightTextfield, sethighlightTextfield] = useState<boolean>(false);
  const [updateMoneyLaundering, { loading, error, data }] = useMutation(UPDATE_MONEYLAUNDERING);
  const router = useIonRouter();

  if (loading) {
    console.log("Loading");
    setTimeout(() => {
      router.push("/home/" + datastore.data.id, "back", "push");
    }, 500);
  }
  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved updatering af Money Laundering data. " + error.message;
  }
  if (data) {
    datastore.data.moneyLaundering.formFilled = true;
  }

  const approveClicked = () => {
    if (
      (datastore.data.moneyLaundering.notIncludedInFinancialSupervisoryAuthorityPEPs &&
        datastore.data.moneyLaundering.notRelatedToPEP &&
        datastore.data.moneyLaundering.notPartnerToPEP) ||
      datastore.data.moneyLaundering.pEPPersonReasonDescription !== ""
    ) {
      updateMoneyLaundering({
        variables: {
          input: {
            id: datastore.data.customerInfo.id,
            moneyLaundering_NotIncludedInFinancialSupervisoryAuthorityPEPs: datastore.data.moneyLaundering.notIncludedInFinancialSupervisoryAuthorityPEPs,
            moneyLaundering_NotRelatedToPEP: datastore.data.moneyLaundering.notRelatedToPEP,
            moneyLaundering_NotPartnerToPEP: datastore.data.moneyLaundering.notPartnerToPEP,
            moneyLaundering_PEPPersonReasonDescription: datastore.data.moneyLaundering.pEPPersonReasonDescription,
          },
        },
      });
    }else{
      sethighlightTextfield(true);
    }
  };

  const cancelClicked = () => {
    if (moneyLaunderingQuestionsData) {
      datastore.data.moneyLaundering = JSON.parse(moneyLaunderingQuestionsData);
    }

    router.push("/home/" + datastore.data.id, "back", "push");
    //router.goBack();
  };

  const updateField = (fieldName: any, fieldValue: any) => {
    (datastore.data.moneyLaundering as any)[fieldName] = fieldValue;
  };

  if (!moneyLaunderingQuestionsData) {
    const moneyLaunderingQuestionsData = JSON.stringify(datastore.data.moneyLaundering);
    setMoneyLaunderingQuestionsData(moneyLaunderingQuestionsData);
  }

  const radioTextClicked = (item:any) => {
    item.currentTarget.previousSibling.click();
  }

  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <h1>Spørgsmål 1</h1>
          <p>
            Jeg bekræfter ved min underskrift på nærværende bilag, at jeg ikke er eller har været optaget på Finanstilsynets liste over{" "}
            <b>
              <u>
                <Link to="/hvidvask/pep">politisk eksponerede personer</Link>
              </u>
            </b>
            , eller i øvrigt opfylder kriterierne for at blive optaget på denne.
          </p>
          <div className="VerticalRadioContainer">
            <input
              type="radio"
              name="notIncludedInFinancialSupervisoryAuthorityPEPs"
              value="true"
              onClick={(e) => updateField("notIncludedInFinancialSupervisoryAuthorityPEPs", true)}
              defaultChecked={datastore.data.moneyLaundering.notIncludedInFinancialSupervisoryAuthorityPEPs}
            />
            <div className="RightRadioText" onClick={radioTextClicked}>Ja</div>
          </div>
          <div className="">
            <input
              type="radio"
              name="notIncludedInFinancialSupervisoryAuthorityPEPs"
              value="false"
              onClick={(e) => updateField("notIncludedInFinancialSupervisoryAuthorityPEPs", false)}
              defaultChecked={!datastore.data.moneyLaundering.notIncludedInFinancialSupervisoryAuthorityPEPs}
            />
            <div className="RightRadioText" onClick={radioTextClicked}>Nej</div>
          </div>
          <hr />
          <h1>Spørgsmål 2</h1>
          <p>
            Jeg erklærer ved min underskrift på nærværende bilag, at jeg ikke er eller har været{" "}
            <b>
              <u>
                <Link to="/hvidvask/pep_familie">nærtstående til en politisk eksponeret person.</Link>
              </u>
            </b>
          </p>
          <div className="VerticalRadioContainer">
            <input
              type="radio"
              name="notRelatedToPEP"
              value="true"
              onClick={(e) => updateField("notRelatedToPEP", true)}
              defaultChecked={datastore.data.moneyLaundering.notRelatedToPEP}
            />
            <div className="RightRadioText" onClick={radioTextClicked}>Ja</div>
          </div>
          <div className="">
            <input
              type="radio"
              name="notRelatedToPEP"
              value="false"
              onClick={(e) => updateField("notRelatedToPEP", false)}
              defaultChecked={!datastore.data.moneyLaundering.notRelatedToPEP}
            />
            <div className="RightRadioText" onClick={radioTextClicked}>Nej</div>
          </div>
          <hr />
          <h1>Spørgsmål 3</h1>
          <p>
            Jeg erklærer ved min underskrift på nærværende bilag, at jeg ikke er eller har været{" "}
            <b>
              <u>
                <Link to="/hvidvask/pep_relation">nær samarbejdspartner</Link>
              </u>
            </b>{" "}
            til en politisk eksponeret person.
          </p>
          <div className="VerticalRadioContainer">
            <input
              type="radio"
              name="notPartnerToPEP"
              value="true"
              onClick={(e) => updateField("notPartnerToPEP", true)}
              defaultChecked={datastore.data.moneyLaundering.notPartnerToPEP}
            />
            <div className="RightRadioText" onClick={radioTextClicked}>Ja</div>
          </div>
          <div className="">
            <input
              type="radio"
              name="notPartnerToPEP"
              value="false"
              onClick={(e) => updateField("notPartnerToPEP", false)}
              defaultChecked={!datastore.data.moneyLaundering.notPartnerToPEP}
            />
            <div className="RightRadioText" onClick={radioTextClicked}>Nej</div>
          </div>
          <br />
          <p>Hvis du ikke kan besvare ovenstående bekræftende, skal dette uddybes herunder:</p>
          <IonTextarea
            auto-grow="true"
            name="pEPPersonReasonDescription"
            value={datastore.data.moneyLaundering.pEPPersonReasonDescription}
            className={highlightTextfield  ? "textarea-field-error" : "textarea-field"}
            onKeyUp={(e) => updateField("pEPPersonReasonDescription", (e.target as HTMLTextAreaElement).value)}
          ></IonTextarea>
          <br></br>
          <br></br> <br></br> <br></br>
          <div className="buttonGroup">
            <Button type="normal" onClick={cancelClicked} text="Annuller" />
            <Button type="highlight" onClick={approveClicked} text="Godkend" />
          </div>
          <br></br>
          <br></br>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default MoneyLaunderingQuestions;
