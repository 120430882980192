import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";
import { useMutation, gql } from "@apollo/client";

const COMPLEATE_AML = gql`
  mutation completeAml($id: ID!) {
    completeAml(id: $id) {
      status
      signUrl
    }
  }
`;

const ApproveInfo: React.FC = () => {
  const [completeAml, { loading, error, data }] = useMutation(COMPLEATE_AML);

  if (data) {
    window.location.href = data.completeAml.signUrl;
  }

  if (loading || data)
    return (
      <IonPage>
        <IonContent>
          <PageContainer>
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
              <h1 style={{ textAlign: "center" }}>
                Opbygger persondatabilaget.
                <br />
                <br />
                øjeblik...
              </h1>
            </div>
          </PageContainer>
        </IonContent>
      </IonPage>
    ); //loading

  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved færdig gørelse af AML flow. " + error.message;
  }

  const acceptedClicked = () => {
    console.log('acceptedClicked')
    const acceptTextElm = document.getElementById("accept");
    if (acceptTextElm){
      console.log('Text removed')
      acceptTextElm.style.display = 'none';
    }
    
    completeAml({
      variables: {
        id: datastore.data.id,
      },
    });
  };

  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <div id="accept">
            <h2>Her kan du læse og godkende dine oplysninger med digital signatur. </h2>
            <p>
              Udover de samtykker og oplysninger du har afgivet, består formularen af yderligere oplysninger til dig fx om hvordan vi behandler dine
              persondataoplysninger.
            </p>
            <br></br>
            <br></br> <br></br> <br></br>
            <div className="buttonGroup">
              <Button type="highlight" onClick={acceptedClicked} text="OK - Gå videre" />
            </div>
            <br></br>
            <br></br>
          </div>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default ApproveInfo;
