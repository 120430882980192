import { useQuery, gql } from "@apollo/client";
import { FC } from "react";
import { datastore } from "../dataStore";
import Done from "./../pages/Done/Done";

const GET_AML = gql`
query getAml($id: ID!){
  aml(id: $id){
    id
    url
    signUrl
    status
    disableIdentificationUpload
    estateCase{
        id
        caseNo
        roadNameAndNumber
        zipcodeAndCity
        caseType
    }     
    customer{
        id
        type
        moneyLaundering_NotIncludedInFinancialSupervisoryAuthorityPEPs
        moneyLaundering_NotPartnerToPEP
        moneyLaundering_NotRelatedToPEP
        moneyLaundering_PEPPersonReasonDescription
    }
    personDataProcessingDoc{
        politicallyExposedPersonSelected
    }
    realEstateBroker{
        id
    }
    uiSettings{
        id
        borderRadius
        buttonColor
        buttonTextColor
        buttonFont
        fontColor
        logoUrl
        mainFont
        mainScreenBackgroundColor
        mainScreenFontColor
        mainScreenHeadingColor
        mainTopBackgroundColor
        mainTopLogoHeight
        textFieldBackground
        textFieldfontColor
    }
    consents{
        id
        heading
        dataField
        value
        text1
        text2
        textComment
    }
  }
}
`;

export const UiStyle: FC = ({ children }) => {
  const amlId :string = window.location.pathname.split('/')[window.location.pathname.split('/').length-1];
  const { loading, error, data } = useQuery(GET_AML, {
    variables: { id: amlId },
  });

  if (loading) return <p></p>; //loading
  if (error) {
    return (<p>AML not found (1)</p>); //TODO: make error component
  }
  if (!data) {
    return (<p>AML not found (2)</p>);
  }

  datastore.data = {
    id: amlId,
    currentItemNo: "",
    validated: false,
    status: data.aml.status,
    signUrl: data.aml.signUrl,
    estateCase: {
      caseNo: data.aml.estateCase.caseNo,
      roadAndNumber: data.aml.estateCase.roadNameAndNumber,
      zipCodeAndCity: data.aml.estateCase.zipcodeAndCity,
      caseType: data.aml.estateCase.caseType
    },
    customerInfo: {
      id: data.aml.customer.id,
      isCompany: false,
      type: data.aml.customer.type,
      ssn: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      birthDate: "",
      citizenship: "Dansk",
      currentAddressCountry: "DK",
      currentAddressFull: "",
      currentAddressRoadnameAndNumber: "",
      currentAddressZipcode: "",
      currentAddressCity: "",
      currentAddressRoadCode: 0, 
      currentAddressRoadName: "", 
      currentAddressHouseNumber: "", 
      currentAddressDoor: "", 
      currentAddressFloor: "",
      planToLiveInEstate: true,
      formFilled: false
    },
    identification: {
      enabled: !data.aml.disableIdentificationUpload,
      imageBase64_1: "",
      imageType_1: "", 
      imageBase64_2: "",
      imageType_2: "",
      imageBase64_3: "",
      imageType_3: "",
      formFilled: false
    },
    moneyLaundering: {
      politicallyExposedPersonSelected: data.aml.personDataProcessingDoc.politicallyExposedPersonSelected,
      notIncludedInFinancialSupervisoryAuthorityPEPs: data.aml.customer.moneyLaundering_NotIncludedInFinancialSupervisoryAuthorityPEPs,
      notRelatedToPEP: data.aml.customer.moneyLaundering_NotRelatedToPEP,
      notPartnerToPEP: data.aml.customer.moneyLaundering_NotPartnerToPEP,
      pEPPersonReasonDescription: data.aml.customer.moneyLaundering_pEPPersonReasonDescription,
      formFilled: false
    },
    personConsents: {
      questions: JSON.parse(JSON.stringify(data.aml.consents)),
      formFilled: false
    },
    uiSettings: {
      borderRadius: data.aml.uiSettings.borderRadius,
      buttonColor: data.aml.uiSettings.buttonColor,
      buttonFont: data.aml.uiSettings.buttonFont,
      buttonTextColor: data.aml.uiSettings.buttonTextColor,
      fontColor: data.aml.uiSettings.fontColor,
      logoUrl: data.aml.uiSettings.logoUrl,
      mainFont: data.aml.uiSettings.mainFont,
      mainScreenBackgroundColor: data.aml.uiSettings.mainScreenBackgroundColor,
      mainScreenFontColor: data.aml.uiSettings.mainScreenFontColor,
      mainScreenHeadingColor: data.aml.uiSettings.mainScreenHeadingColor,
      mainTopBackgroundColor: data.aml.uiSettings.mainTopBackgroundColor,
      mainTopLogoHeight: data.aml.uiSettings.mainTopLogoHeight,
      textFieldBackground: data.aml.uiSettings.textFieldBackground,
      textFieldfontColor: data.aml.uiSettings.textFieldfontColor
    },
    ErrorMessage: ""
  };

  (datastore.data.personConsents.questions as Array<any>).map((item) => {
    if (item.value === "trueValue") {
      item.value = true;
    }
    if (item.value === "falseValue") {
      item.value = false;
    }
    if (item.value === "notSet") {
      item.value = null;
    }

    return item;
  });

  //console.log(datastore);

  let root = document.documentElement;
  root.style.setProperty("--mainTopLogoHeight", datastore.data.uiSettings.mainTopLogoHeight + "px");
  root.style.setProperty("--mainTopBackgroundColor", datastore.data.uiSettings.mainTopBackgroundColor);
  root.style.setProperty("--mainScreenHeadingColor", datastore.data.uiSettings.mainScreenHeadingColor);
  root.style.setProperty("--mainScreenBackgroundColor", datastore.data.uiSettings.mainScreenBackgroundColor);
  root.style.setProperty("--mainScreenFontColor", datastore.data.uiSettings.mainScreenFontColor);
  root.style.setProperty("--fontColor", datastore.data.uiSettings.fontColor);
  root.style.setProperty("--textFieldBackground", datastore.data.uiSettings.textFieldBackground);
  root.style.setProperty("--textFieldfontColor", datastore.data.uiSettings.textFieldfontColor);
  root.style.setProperty("--borderRadius", datastore.data.uiSettings.borderRadius + "px");
  root.style.setProperty("--buttonColor", datastore.data.uiSettings.buttonColor);
  root.style.setProperty("--buttonTextColor", datastore.data.uiSettings.buttonTextColor);
  root.style.setProperty("--buttonFont", datastore.data.uiSettings.buttonFont);
  root.style.setProperty("--MainFont", datastore.data.uiSettings.mainFont);
  root.style.setProperty("--logoUrl", "url(" + datastore.data.uiSettings.logoUrl + ")");

  if (datastore.data.status === 'completed'){ //completed
    return <Done/>
  }else{
    return <div>{children}</div>;
  }
};
