import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import React, { useState } from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageContainer from "../../components/PageContainer/PageContainer";
import Button from "../../components/Button/Button";
import "./Identification.css";
import { datastore } from "../../dataStore";
import { useMutation, gql } from "@apollo/client";
import { BrowserView, MobileView } from "react-device-detect";
//import Resizer from "react-image-file-resizer";
import ImageResize from "image-resize";
const ml5 = require("ml5");

const UPDATE_IDENTIFICATION = gql`
  mutation updateIdentification($input: UpdateIdentificationInput!) {
    updateIdentification(input: $input)
  }
`;

const Identification: React.FC = () => {
  const [classifier, setclassifier] = React.useState<any>(null);
  const [identificationData, setIdentificationData] = useState<string | null>(null);
  const [updateIdentification, { loading, error, data }] = useMutation(UPDATE_IDENTIFICATION);
  const [currentImgNo, setcurrentImgNo] = React.useState<Number>(0);
  const [imgSrc1, setImgSrc1] = React.useState<string | null>(null);
  const [imgSrc2, setImgSrc2] = React.useState<string | null>(null);
  const [imgSrc3, setImgSrc3] = React.useState<string | null>(null);
  const [imgType1, setImgType1] = React.useState<string>("");
  const [imgType2, setImgType2] = React.useState<string>("");
  const [imgType3, setImgType3] = React.useState<string>("");
  const [isAiInited, setIsAiInited] = React.useState<boolean>(false);
  const [isImagesValid, setIsImagesValid] = React.useState<boolean>(true);

  const photo1UploadText = 'klik her for at uploade et billede af dit<br/><b style="font-size:22px">SUNDHEDSKORT</b>';
  const photo1UploadTextMobile = 'klik her for at tag eller uploade et billede af dit<br/><b style="font-size:22px">SUNDHEDSKORT</b>';
  const photo2UploadText = 'klik her for at uploade et billede af dit<br/><b style="font-size:22px">PAS ELLER KØREKORT</b>';
  const photo2UploadTextMobile = 'klik her for at tag eller uploade et billede af dit<br/><b style="font-size:22px">PAS ELLER KØREKORT</b>';
  const photo3UploadText = 'klik her for at uploade et billede af dit<br/><b style="font-size:22px">KØREKORTS INFO</b>';
  const photo3UploadTextMobile = 'klik her for at tag eller uploade et billede af dit<br/><b style="font-size:22px">KØREKORTS INFO</b>';
  const [photoUploadText, setPhotoUploadText] = React.useState<string>(photo1UploadText);
  const [photoUploadTextMobile, setPhotoUploadTextMobile] = React.useState<string>(photo1UploadTextMobile);

  const [photoState, setPhotoState] = React.useState<string>("takePhoto");
  const router = useIonRouter();

  if (datastore.data.identification.imageBase64_1 !== "" && imgSrc1 === null) {
    setImgSrc1(datastore.data.identification.imageBase64_1);
    setImgType1(datastore.data.identification.imageType_1);
    setPhotoState("showPhotos");
  }

  if (datastore.data.identification.imageBase64_2 !== "" && imgSrc2 === null) {
    setImgSrc2(datastore.data.identification.imageBase64_2);
    setImgType2(datastore.data.identification.imageType_2);
    setPhotoState("showPhotos");
  }

  if (datastore.data.identification.imageBase64_3 !== "" && imgSrc3 === null) {
    setImgSrc3(datastore.data.identification.imageBase64_3);
    setImgType3(datastore.data.identification.imageType_3);
    setPhotoState("showPhotos");
  }

  if (loading) {
    console.log("Loading");
  }

  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved updatering af identifikations data. " + error.message;
  }
  if (data) {
    datastore.data.identification.formFilled = true;
    if (imgSrc1) {
      setTimeout(() => {
        router.push("/home/" + datastore.data.id, "back", "push");
      }, 500);
    }
  }

  const addImage2Clicked = () => {
    setcurrentImgNo(1);

    if (imgSrc2) {
      setPhotoState("showPhotos");
    } else {
      setPhotoState("takePhoto");
    }

    setPhotoUploadText(photo2UploadText);
    setPhotoUploadTextMobile(photo2UploadTextMobile);

    //document.getElementById('photo_upload')?.click();
  };
  const addImage3Clicked = () => {
    setcurrentImgNo(2);

    if (imgSrc3) {
      setPhotoState("showPhotos");
    } else {
      setPhotoState("takePhoto");
    }

    setPhotoUploadText(photo3UploadText);
    setPhotoUploadTextMobile(photo3UploadTextMobile);

    //document.getElementById("photo_upload")?.click();
  };

  const validateImages = () => {
    //debugger;
    const acceptedImages1 = ["Sygesikring", "Sygesikring_app_m_...", "Sygesikring_app_u_..."];
    if (!acceptedImages1.includes(datastore.data.identification.imageType_1)) {
      return false;
    }
    const acceptedImages2 = ["Kørekort", "Kørekort_app", "Pas"];
    if (!acceptedImages2.includes(datastore.data.identification.imageType_2)) {
      return false;
    }

    if (datastore.data.identification.imageType_2 === "Kørekort_app") {
      if (datastore.data.identification.imageType_3 !== "Kørekort_app_info") {
        return false;
      }
    }

    return true;
  };

  const approveClicked = (force: boolean) => {
    //console.log('-->', datastore.data.identification)

    if (validateImages() || force) {
      let Idinput = {
        id: datastore.data.customerInfo.id,
        identification_Img1: "",
        identification_Img1_Type: "",
        identification_Img2: "",
        identification_Img2_Type: "",
        identification_Img3: "",
        identification_Img3_Type: "",
      };

      if (datastore.data.identification.imageBase64_1 !== "") {
        Idinput.identification_Img1 = datastore.data.identification.imageBase64_1;
        Idinput.identification_Img1_Type = datastore.data.identification.imageType_1;
      }
      if (datastore.data.identification.imageBase64_2 !== "") {
        Idinput.identification_Img2 = datastore.data.identification.imageBase64_2;
        Idinput.identification_Img2_Type = datastore.data.identification.imageType_2;
      }
      if (datastore.data.identification.imageBase64_3 !== "") {
        Idinput.identification_Img3 = datastore.data.identification.imageBase64_3;
        Idinput.identification_Img3_Type = datastore.data.identification.imageType_3;
      }

      if (Idinput.identification_Img1 !== "" || Idinput.identification_Img2 !== "" || Idinput.identification_Img3 !== "") {
        updateIdentification({
          variables: {
            input: Idinput,
          },
        });
      }
    } else {
      //not all images are valid

      setIsImagesValid(false);
    }
  };

  const remove1ImageClicked = () => {
    setImgSrc1(null);
    setImgType1("");
    datastore.data.identification.imageBase64_1 = "";
    datastore.data.identification.imageType_1 = "";
    setPhotoState("takePhoto");

    setcurrentImgNo(0);
    setPhotoUploadText(photo1UploadText);
    setPhotoUploadTextMobile(photo1UploadTextMobile);
  };

  const remove2ImageClicked = () => {
    setImgSrc2(null);
    setImgType2("");
    datastore.data.identification.imageBase64_2 = "";
    datastore.data.identification.imageType_2 = "";
    setcurrentImgNo(1);
    setPhotoUploadText(photo2UploadText);
    setPhotoUploadTextMobile(photo2UploadTextMobile);

    setPhotoState("takePhoto");
  };

  const remove3ImageClicked = () => {
    setImgSrc3(null);
    setImgType3("");
    datastore.data.identification.imageBase64_3 = "";
    datastore.data.identification.imageType_3 = "";
    setcurrentImgNo(2);
    setPhotoUploadText(photo3UploadText);
    setPhotoUploadTextMobile(photo3UploadTextMobile);
    setPhotoState("takePhoto");
  };

  const back1Clicked = () => {
    cancelClicked();
  };

  const back2Clicked = () => {
    setcurrentImgNo(0);
    setPhotoUploadText(photo1UploadText);
    setPhotoUploadTextMobile(photo1UploadTextMobile);

    if (imgSrc1) {
      setPhotoState("showPhotos");
    } else {
      setPhotoState("takePhoto");
    }
  };

  const back3Clicked = () => {
    setcurrentImgNo(1);
    setPhotoUploadText(photo2UploadText);
    setPhotoUploadTextMobile(photo2UploadTextMobile);

    if (imgSrc2) {
      setPhotoState("showPhotos");
    } else {
      setPhotoState("takePhoto");
    }
  };

  const back4Clicked = () => {
    setIsImagesValid(true);
    if (imgSrc3) {
      setcurrentImgNo(2);
      setPhotoUploadText(photo3UploadText);
      setPhotoUploadTextMobile(photo3UploadTextMobile);

      setPhotoState("showPhotos");
    } else {
      back3Clicked();
    }
  };

  const cancelClicked = () => {
    if (identificationData) {
      datastore.data.identification = JSON.parse(identificationData);
    }

    router.goBack();
  };

  var image1Resize = new ImageResize({
    format: "jpg",
    width: 1044,
    height: 1044,
  });

  var image2Resize = new ImageResize({
    format: "jpg",
    width: 1044,
    height: 1044,
  });

  var image3Resize = new ImageResize({
    format: "jpg",
    width: 244,
    height: 244,
  });

  const fileInputChange = async () => {
    const fileInput: any = document.getElementById("photo_upload");
    if (fileInput?.files.length > 0) {
      if (currentImgNo === 0) {
        const resisedImage: any = await image1Resize.play(fileInput.files[0]);
        const imgBase64: any = await toBase64(fileInput.files[0]);
        setImgSrc1(imgBase64);
        datastore.data.identification.imageBase64_1 = imgBase64;
        setPhotoState("showPhotos");

        //AI
        let image = await new Promise(function (resolve, reject) {
          var image = new Image();
          image.src = resisedImage;
          image.crossOrigin = "anonymous";
          image.onload = () => resolve(image);
          image.onerror = reject;
        });

        let results = await classifier.classify(image);

        if (results[0].confidence > 0.995) {
          setImgType1(results[0].label);
          datastore.data.identification.imageType_1 = results[0].label;
        } else {
          setImgType1("Andet");
          datastore.data.identification.imageType_1 = "Andet";
        }

        console.log(results);
      }

      if (currentImgNo === 1) {
        const resisedImage: any = await image2Resize.play(fileInput.files[0]);
        const imgBase64: any = await toBase64(fileInput.files[0]);
        setImgSrc2(imgBase64);
        datastore.data.identification.imageBase64_2 = imgBase64;
        setPhotoState("showPhotos");

        //AI
        let image = await new Promise(function (resolve, reject) {
          var image = new Image();
          image.src = resisedImage;
          image.crossOrigin = "anonymous";
          image.onload = () => resolve(image);
          image.onerror = reject;
        });

        let results = await classifier.classify(image);

        if (results[0].confidence > 0.995) {
          setImgType2(results[0].label);
          datastore.data.identification.imageType_2 = results[0].label;
        } else {
          setImgType2("Andet");
          datastore.data.identification.imageType_2 = "Andet";
        }

        console.log(results);
      }

      if (currentImgNo === 2) {
        const resisedImage: any = await image3Resize.play(fileInput.files[0]);
        const imgBase64: any = await toBase64(fileInput.files[0]);
        setImgSrc3(imgBase64);
        datastore.data.identification.imageBase64_3 = imgBase64;
        setPhotoState("showPhotos");

        //AI
        let image = await new Promise(function (resolve, reject) {
          var image = new Image();
          image.src = resisedImage;
          image.crossOrigin = "anonymous";
          image.onload = () => resolve(image);
          image.onerror = reject;
        });

        let results = await classifier.classify(image);

        if (results[0].confidence > 0.995) {
          setImgType3(results[0].label);
          datastore.data.identification.imageType_3 = results[0].label;
        } else {
          setImgType3("Andet");
          datastore.data.identification.imageType_2 = "Andet";
        }

        console.log(results);
      }

      const uploadInput = document.getElementById("photo_upload") as HTMLInputElement | null;
      if (uploadInput) {
        uploadInput.value = "";
      }
    }
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addPhotoClick = () => {
    document.getElementById("photo_upload")?.click();
  };

  if (!identificationData) {
    const identificationData = JSON.stringify(datastore.data.identification);
    setIdentificationData(identificationData);
  }

  if (!classifier) {
    (async () => {
      const tmpClassifier = await ml5.imageClassifier("./model/model.json", modelLoaded);
      setclassifier(tmpClassifier);
      console.log("AI Model Loaded!!");
      
    })();
  }

  if (classifier && !isAiInited) {
    (async () => {
      let image = await new Promise(function (resolve, reject) {
        var image = new Image();
        image.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
        image.crossOrigin = "anonymous";
        image.onload = () => resolve(image);
        image.onerror = reject;
      });

      let results = await classifier.classify(image);
      console.log("pre ai:", results);
      setIsAiInited(true)
    })();
  }

  // When the model is loaded
  function modelLoaded() {
    console.log("AI Model Loaded!");

  }

  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <PageHeading itemHeading="Legitimation" itemText="Tag et billede af dit sundhedskort og dit pas eller kørekort.<br/>Upload de to dokumenter her." itemNo={datastore.data.currentItemNo} />

          <img alt="" id={"AI"} src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="} />
          <input type="file" id="photo_upload" data-role="none" className="photo_upload" accept="image/jpeg" onChange={fileInputChange} />
          {loading || data ? (
            <div className="savingDataText">Gemmer legitimation, øjeblik...</div>
          ) : (
            <>
              {isImagesValid ? (
                <>
                  {photoState === "takePhoto" && (
                    <div>
                      <BrowserView>
                        <div className="BorderCorner_wrap">
                          <div className="BorderCorner camaraBorders" onClick={addPhotoClick}>
                            <div className="addPhotoBox">
                              <svg xmlns="http://www.w3.org/2000/svg" className="uploadIcon" viewBox="0 0 512 512">
                                <title>Cloud Upload</title>
                                <path
                                  d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="32"
                                />
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 255.79l-64-64-64 64M256 448.21V207.79" />
                              </svg>
                              <br />
                              <div className="uploadText" dangerouslySetInnerHTML={{ __html: photoUploadText }}></div>
                            </div>
                          </div>
                        </div>
                      </BrowserView>
                      <MobileView>
                        <div className="BorderCorner_wrap">
                          <div className="BorderCorner camaraBorders" onClick={addPhotoClick}>
                            <div className="addPhotoBox">
                              <svg xmlns="http://www.w3.org/2000/svg" className="uploadIcon" viewBox="0 0 512 512">
                                <title>Cloud Upload</title>
                                <path
                                  d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="32"
                                />
                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 255.79l-64-64-64 64M256 448.21V207.79" />
                              </svg>
                              <br />
                              <div className="uploadText" dangerouslySetInnerHTML={{ __html: photoUploadTextMobile }}></div>
                            </div>
                          </div>
                        </div>
                      </MobileView>

                      <div className="uploadInfoText">Tag billedet så tæt på som muligt, uden at noget bliver skåret væk.</div>

                      {currentImgNo === 0 && (
                        <div className="buttonGroup">
                          <Button type="normal" onClick={back1Clicked} text="Tilbage" />
                        </div>
                      )}

                      {currentImgNo === 1 && (
                        <div className="buttonGroup">
                          <Button type="normal" onClick={back2Clicked} text="Tilbage" />
                        </div>
                      )}
                      {currentImgNo === 2 && (
                        <div className="buttonGroup">
                          <Button type="normal" onClick={back3Clicked} text="Tilbage" />
                        </div>
                      )}
                    </div>
                  )}

                  {photoState === "showPhotos" && (
                    <div>
                      <div>
                        {currentImgNo === 0 && imgSrc1 && (
                          <div>
                            <div className="BorderCorner_wrap">
                              <div className="BorderCorner">
                                <img alt="" src={imgSrc1} />
                              </div>
                              {imgType1 === "" && (
                                <div className="imageTextContainer">
                                  <div className="imageText">Gennemser billedet, øjeblik...</div>
                                </div>
                              )}
                              {imgType1 === "Andet" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Kørekort" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Kørekort_app" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Kørekort_app_info" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Kørekort_og_sygesi..." && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Pas" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Pas_og_sygesikring" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et sundhedskort
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Sygesikring" && (
                                <div className="imageTextContainer">
                                  <svg className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                  </svg>
                                  <div className="imageText">Sundhedskort</div>
                                </div>
                              )}
                              {imgType1 === "Sygesikring_app_m_..." && (
                                <div className="imageTextContainer">
                                  <div className="imageText">
                                    <svg style={{ position: "relative", top: "5px" }} className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                    </svg>
                                    Sundhedskort app
                                    <br />
                                    (Husk at sikre dig, at hele CPR-nummeret bliver vist)
                                  </div>
                                </div>
                              )}
                              {imgType1 === "Sygesikring_app_u_..." && (
                                <div className="imageTextContainer">
                                  <div className="imageText">
                                    <svg style={{ position: "relative", top: "5px" }} className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                    </svg>
                                    Sundhedskort app
                                    <br />
                                    (Husk at sikre dig, at hele CPR-nummeret bliver vist)
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="buttonGroup">
                              <Button type="normal" onClick={remove1ImageClicked} text="Fjern billede" />
                              <Button type="normal" onClick={addImage2Clicked} text="Næste" />
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        {currentImgNo === 1 && imgSrc2 && (
                          <div>
                            <div className="BorderCorner_wrap">
                              <div className="BorderCorner">
                                <img alt="" src={imgSrc2} />
                              </div>
                              {imgType2 === "Andet" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                              {imgType2 === "Kørekort" && (
                                <div className="imageTextContainer">
                                  <svg className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                  </svg>
                                  <div className="imageText">Kørekort</div>
                                </div>
                              )}
                              {imgType2 === "Kørekort_app" && (
                                <div className="imageTextContainer">
                                  <svg className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                  </svg>
                                  <div className="imageText">Kørekort app, husk billede af info siden</div>
                                </div>
                              )}
                              {imgType2 === "Kørekort_app_info" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                              {imgType2 === "Kørekort_og_sygesi..." && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                              {imgType2 === "Pas" && (
                                <div className="imageTextContainer">
                                  <svg className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                  </svg>
                                  <div className="imageText">Pas</div>
                                </div>
                              )}
                              {imgType2 === "Pas_og_sygesikring" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                              {imgType2 === "Sygesikring" && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                              {imgType2 === "Sygesikring_app_m_..." && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                              {imgType2 === "Sygesikring_app_u_..." && (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som et pas eller kørekort
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="buttonGroup">
                              <Button type="normal" onClick={remove2ImageClicked} text="Fjern billede" />
                            </div>
                            <div className="buttonGroup">
                              <Button type="normal" onClick={back2Clicked} text="Tilbage" />
                              {imgType2 === "Kørekort_app" && <Button type="normal" onClick={addImage3Clicked} text="Næste" />}
                              {imgType2 !== "Kørekort_app" && (
                                <Button
                                  type="highlight"
                                  onClick={() => {
                                    approveClicked(false);
                                  }}
                                  text="Godkend"
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        {currentImgNo === 2 && imgSrc3 && (
                          <div>
                            <div className="BorderCorner_wrap">
                              <div className="BorderCorner">
                                <img alt="" src={imgSrc3} />
                              </div>

                              {imgType3 === "Kørekort_app_info" ? (
                                <div className="imageTextContainer">
                                  <svg className="imageTextIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="50" d="M416 128L192 384l-96-96" />
                                  </svg>
                                  <div className="imageText">Kørekort info</div>
                                </div>
                              ) : (
                                <div className="imageTextContainer">
                                  <div className="imageTextAdvarsel">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="imageTextIconWarning" viewBox="0 0 512 512" style={{ position: "relative", top: "7px", paddingRight: "5px" }}>
                                      <title>Warning</title>
                                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                                    </svg>
                                    Billedet kan ikke genkendes som kørekortets infoside.
                                    <br />
                                    Du kan finde infoside under sektionen "Kortinfo" i appen.
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="buttonGroup">
                              <Button type="normal" onClick={remove3ImageClicked} text="Fjern billede" />
                            </div>
                            <div className="buttonGroup">
                              <Button type="normal" onClick={back3Clicked} text="Tilbage" />
                              <Button
                                type="highlight"
                                onClick={() => {
                                  approveClicked(false);
                                }}
                                text="Godkend"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="warningSection">
                    <svg xmlns="http://www.w3.org/2000/svg" className="warningBig" viewBox="0 0 512 512">
                      <title>Warning</title>
                      <path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                      <path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                      <path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" />
                    </svg>
                  </div>
                  <div className="uploadInfoText">Ikke alle billeder kan identificeres<br/>Er du sikker på, at du ønsker at fortsætte?</div>

                  <div className="buttonGroup">
                    <Button type="normal" onClick={back4Clicked} text="Tilbage" />
                    <Button
                      type="highlight"
                      onClick={() => {
                        approveClicked(true);
                      }}
                      text="Forsæt"
                    />
                  </div>
                </>
              )}

              <div>
                <p className="textNote">* Ejendomsmælgeren vil kunne bede dig om andre/ydeligere oplysninger og dokumenter, hvis ejendomsmægleren finder anledning til det.</p>
                <br />
              </div>
            </>
          )}
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default Identification;
