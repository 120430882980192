import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";

const MoneyLaunderingInfo: React.FC = () => {
  const router = useIonRouter();

  const acceptedClicked = () => {
    router.push("/hvidvask/sp/" + datastore.data.id, "forward", "push");
  };

  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <h2>Personlige oplysninger omkring hvidvask</h2>
          <p>
            For at vi kan opfylde vores forpligtelser iht. hvidvaskloven, får vi udover dine kontaktoplysninger også dit cpr-nr. og
            yderligere kontroloplysninger (f.eks. legitimation). Disse bruges til den kundekendskabsprocedure, vi skal gennemføre. Vi har en
            underretningspligt, som betyder at dine personoplysninger kan blive videregivet til offentlige myndigheder. Vi opbevarer de
            oplysninger om dig, der kun vedrører hvidvasklovens forpligtelser, i 5 år efter sagens afslutning.
          </p>
          <br></br>
          <br></br> <br></br> <br></br>
          <div className="buttonGroup">
            <Button type="highlight" onClick={acceptedClicked} text="OK - Gå videre" />
          </div>
          <br></br>
          <br></br>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default MoneyLaunderingInfo;
