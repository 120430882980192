import "./Button.css";

interface Props {
  text: string;
  type: "highlight" | "normal" | "lowlight";
  onClick: () => void;
}

const Button: React.FC<Props> = (props) => {
  let buttonClass = "buttonContainer";

  switch (props.type) {
    case "highlight":
      buttonClass = "buttonHighLigtedContainer";
      break;
    case "normal":
      buttonClass = "buttonContainer";
      break;
    case "lowlight":
      buttonClass = "buttonLowLigtedContainer";
      break;
  }

  return (
    <div className={buttonClass} onClick={(e) => props.onClick()}>
      <div className="buttonText">{props.text}</div>
    </div>
  );
};

export default Button;
