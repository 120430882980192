import React, { useState } from "react";
import { useIonRouter } from "@ionic/react";
import IonIcon from "@reacticons/ionicons";
import "./BackButton.css";
import { datastore } from "../../dataStore";

interface Props {}

const BackButton: React.FC<Props> = (props) => {
  const router = useIonRouter();
  const [amlData, setAmlData] = useState<string | null>(null);

  const goBack = () => {
    if (amlData) {
      datastore.data = JSON.parse(amlData);
    }

    router.goBack();
  };

  if (!amlData) {
    const amlData = JSON.stringify(datastore.data);
    setAmlData(amlData);
  }

  return (
    <div onClick={goBack} className="backButton">
      <IonIcon name="arrow-back-outline" className="backButtonIcon"></IonIcon>
      <div className="backButtonText">Tilbage</div>
    </div>
  );
};

export default BackButton;