export let datastore = {
    data: {
        id: "",
        validated: false,
        status: "",
        signUrl: "",
        currentItemNo: "",
        estateCase: {
            caseNo: "",
            roadAndNumber:"",
            zipCodeAndCity:"",
            caseType:""
        },
        customerInfo: {
            id: "",
            isCompany: false,
            type: "",
            ssn:"",
            firstName: "",
            lastName:"",
            email:"",
            phone:"",
            birthDate:"",
            citizenship:"Dansk",
            currentAddressCountry: "DK",
            currentAddressFull: "",
            currentAddressRoadnameAndNumber:"",
            currentAddressZipcode:"",
            currentAddressCity:"",
            currentAddressRoadCode: 0,
            currentAddressRoadName: "",
            currentAddressHouseNumber: "",
            currentAddressDoor: "",
            currentAddressFloor: "",
            planToLiveInEstate:true,
            formFilled: false
        },
        identification:{
            enabled: true,
            imageBase64_1: "",
            imageType_1: "",
            imageBase64_2: "",
            imageType_2: "",
            imageBase64_3: "",
            imageType_3: "",
            formFilled: false
        },
        moneyLaundering:{
            politicallyExposedPersonSelected: true,
            notIncludedInFinancialSupervisoryAuthorityPEPs: true,
            notRelatedToPEP: true,
            notPartnerToPEP: true,
            pEPPersonReasonDescription: "",
            formFilled: false
        },
        personConsents:{
            questions: [],
            formFilled: false
        },
        uiSettings: {
            borderRadius: 0,
            buttonColor: "",
            buttonFont: "Poppins",
            buttonTextColor: "",
            fontColor: "",
            logoUrl: "",
            mainFont: "",
            mainScreenBackgroundColor: "",
            mainScreenFontColor: "",
            mainScreenHeadingColor: "",
            mainTopBackgroundColor: "",
            mainTopLogoHeight: 0,
            textFieldBackground: "",
            textFieldfontColor: ""
        },
        //flytnemt:{
        //    questions: [],
        //    formFilled: false
        //},
        ErrorMessage: ""
    }
};