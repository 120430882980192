import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import React, { useState } from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";
import Button from "../../components/Button/Button";
import { datastore } from "../../dataStore";
import { useMutation, gql } from "@apollo/client";
import PageHeading from "../../components/PageHeading/PageHeading";

const UPDATE_CONSENTS = gql`
  mutation updateCustomerConsents($orderId: String!, $input: [updateAmlCustomerConsentsInput]!) {
    updateCustomerConsents(orderId: $orderId, input: $input)
  }
`;

const ConsentsEdit: React.FC = () => {
  const router = useIonRouter();
  const [validate, setValidate] = useState<boolean>(false);
  const [consentData, setconsentData] = useState<string | null>(null);
  const [updateConsents, { loading, error, data }] = useMutation(UPDATE_CONSENTS);

  if (loading) {
    console.log("Loading");
    
  }

  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved updatering af kunde sammentykker. " + error.message;
  }
  if (data) {
    datastore.data.personConsents.formFilled = true;
    setTimeout(() => {
      setValidate(false);
      router.push("/home/" + datastore.data.id, "back", "push");
    }, 200);
  }

  const acceptedClicked = () => {

    setValidate(true);

    let isvalid: boolean;
    isvalid = true;

    let variables = {
      orderId: datastore.data.id,
      input: [],
    };

    for (var i = 0; i < datastore.data.personConsents.questions.length; i++) {
      let consentData = {
        consentId: (datastore.data.personConsents.questions[i] as any).id,
        value: (datastore.data.personConsents.questions[i] as any).value,
      };

      switch (consentData.value) {
        case true:
          consentData.value = "trueValue"
          break;
        case false:
          consentData.value = "falseValue"
          break;
        default:
          isvalid = false;
          consentData.value = "notSet"
          break;
      }

      (variables.input as Array<any>).push(consentData);
    }
    if (isvalid) {
      updateConsents({
        variables: variables,
      });
    }
  };

  const cancelClicked = () => {
    if (consentData) {
      datastore.data.personConsents.questions = JSON.parse(consentData);
    }
    router.goBack();
    //router.push("/samtykker", "back", "push");
  };

  const updateField = (fieldName: any, fieldValue: any) => {
    let consent = (datastore.data.personConsents.questions as Array<any>).find((consent) => consent.id === fieldName);
    if (consent) {
      consent.value = fieldValue;
    }
  };

  if (!consentData) {
    const consentData = JSON.stringify(datastore.data.personConsents.questions);
    setconsentData(consentData);
  }

  const radioTextClicked = (item: any) => {
    item.currentTarget.previousSibling.click();
  }

  //<h2>Rediger og godkend samtykke.</h2>
          
  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <PageHeading itemHeading="Rediger og godkend samtykke." itemText="Vi skal have samtykke til at behandle dine personoplysninger. Du bedes derfor samtykke til følgende:" itemNo={datastore.data.currentItemNo} />

  
          <ul className="number">
            {(datastore.data.personConsents.questions as Array<any>).map((item) => {
              return (
                <li key={item.id} className={validate && item.value !== null ? "li_OK" : ""}>
                  <h2>{item.heading}</h2>
                  <p dangerouslySetInnerHTML={{__html:item.text1}}></p>
                  <p dangerouslySetInnerHTML={{__html:item.text2}}></p>
                  <div className="VerticalRadioContainer">
                    <input
                      type="radio"
                      value="true"
                      name={item.id}
                      onClick={(e) => updateField(item.id, true)}
                      defaultChecked={item.value === true}
                    />
                    <div className={"RightRadioTextConsents"} onClick={radioTextClicked}>Ja</div>
                  </div>
                  <div className="">
                    <input
                      type="radio"
                      value="false"
                      name={item.id}
                      onClick={(e) => updateField(item.id, false)}
                      defaultChecked={item.value === false}
                    />
                    <div className={"RightRadioTextConsents"} onClick={radioTextClicked}>Nej</div>
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="comment">
            Det/de ovenstående samtykke(r) kan som udgangspunkt (samlet eller hver for sig) til enhver tid tilbagekaldes ved henvendelse til ejendomsmæglervirksomheden. Dette er dog bl.a. ikke muligt, hvis ydelsen, som samtykket var nødvendigt for at gennemføre, er påbegyndt og ikke kan indstilles på tidspunktet for tilbagekaldelsen.
          </div>

          <div className="buttonGroup">
            <Button type="normal" onClick={cancelClicked} text="Annuller" />
            <Button type="highlight" onClick={acceptedClicked} text="Godkend" />
          </div>
          <br></br>
          <br></br>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default ConsentsEdit;
