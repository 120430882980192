import ReactDOM from "react-dom";
import App from "./App";
import { UiStyle } from "./providers/uiStyle";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://mw.apiaml.justsign.dk",
  //uri: 'http://localhost:5000',
  cache: new InMemoryCache(),
  headers: {
    //authorization: "Bearer 74230f61-acbb-4052-8cdf-c2d8a0c554b2",
    authorization: "Bearer c78245dbttf0014be19e613e5eab6f12e4074d1664219042a88a2a2547cc349cb288e2279c790f4d90905935cdbc4666aff6cea1a7450a4a7d88549f625104eb50",
  },
});

//<React.StrictMode> //calls fetch and mutation twich in debug mode, making it hard to test: https://reactjs.org/docs/strict-mode.html
ReactDOM.render(
  <ApolloProvider client={client}>
    <UiStyle>
      <App />
    </UiStyle>
  </ApolloProvider>,
  document.getElementById("root")
);
