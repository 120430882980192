export const Countries = [
  { value: "AF", text: "Afghanistan" },
  { value: "AL", text: "Albanien" },
  { value: "DZ", text: "Algeriet" },
  { value: "AS", text: "Amerikansk Samoa" },
  { value: "US", text: "USA" },
  { value: "AD", text: "Andorra" },
  { value: "AO", text: "Angola" },
  { value: "AI", text: "Anguilla" },
  { value: "AQ", text: "Antarktis Antarktika" },
  { value: "AG", text: "Antigua og Barbuda" },
  { value: "AR", text: "Argentina" },
  { value: "AM", text: "Armenien" },
  { value: "AW", text: "Aruba" },
  { value: "AU", text: "Australien" },
  { value: "AZ", text: "Aserbajdsjan" },
  { value: "BS", text: "Bahamas" },
  { value: "BH", text: "Bahrain" },
  { value: "BD", text: "Bangladesh" },
  { value: "BB", text: "Barbados" },
  { value: "BE", text: "Belgien" },
  { value: "BZ", text: "Belize" },
  { value: "BJ", text: "Benin" },
  { value: "BM", text: "Bermuda" },
  { value: "BT", text: "Bhutan" },
  { value: "BO", text: "Bolivia" },
  { value: "BA", text: "Bosnien-Hercegovina" },
  { value: "BW", text: "Botswana" },
  { value: "BV", text: "Bouvetøen" },
  { value: "BR", text: "Brasilien" },
  { value: "IO", text: "British Indian Ocean Territory" },
  { value: "BN", text: "Brunei" },
  { value: "BG", text: "Bulgarien" },
  { value: "BF", text: "Burkina Faso" },
  { value: "BI", text: "Burundi" },
  { value: "CA", text: "Canada" },
  { value: "KY", text: "Caymanøerne" },
  { value: "CL", text: "Chile" },
  { value: "CX", text: "Juleøen" },
  { value: "CO", text: "Colombia" },
  { value: "CK", text: "Cookøerne" },
  { value: "CR", text: "Costa Rica" },
  { value: "CU", text: "Cuba" },
  { value: "CW", text: "Curaçao" },
  { value: "DK", text: "Danmark" },
  { value: "DJ", text: "Djibouti" },
  { value: "DM", text: "Dominica" },
  { value: "DO", text: "Dominikanske Republik" },
  { value: "EC", text: "Ecuador" },
  { value: "EG", text: "Egypten" },
  { value: "CI", text: "Elfenbenskysten" },
  { value: "GQ", text: "Ækvatorialguinea" },
  { value: "SV", text: "El Salvador" },
  { value: "ER", text: "Eritrea" },
  { value: "EE", text: "Estland" },
  { value: "ET", text: "Etiopien" },
  { value: "FK", text: "Falklandsøerne" },
  { value: "FJ", text: "Fiji" },
  { value: "PH", text: "Filippinerne" },
  { value: "FI", text: "Finland" },
  { value: "FR", text: "Frankrig" },
  { value: "GF", text: "Fransk Guiana" },
  { value: "PF", text: "Fransk Polynesien" },
  { value: "TF", text: "Franske Sydterritorier" },
  { value: "FO", text: "Færøerne" },
  { value: "GA", text: "Gabon" },
  { value: "GM", text: "Gambia" },
  { value: "GE", text: "Georgien" },
  { value: "GH", text: "Ghana" },
  { value: "GI", text: "Gibraltar" },
  { value: "GD", text: "Grenada" },
  { value: "GL", text: "Grønland" },
  { value: "GP", text: "Guadeloupe" },
  { value: "GU", text: "Guam" },
  { value: "GT", text: "Guatemala" },
  { value: "GN", text: "Guinea" },
  { value: "GW", text: "Guinea-Bissau" },
  { value: "GY", text: "Guyana" },
  { value: "HT", text: "Haiti" },
  { value: "HM", text: "Heard-øen og McDonald-øerne" },
  { value: "GR", text: "Grækenland" },
  { value: "HN", text: "Honduras" },
  { value: "HK", text: "Hongkong" },
  { value: "BY", text: "Hviderusland" },
  { value: "IN", text: "Indien" },
  { value: "ID", text: "Indonesien" },
  { value: "IQ", text: "Irak" },
  { value: "IR", text: "Iran" },
  { value: "IE", text: "Irland" },
  { value: "IS", text: "Island" },
  { value: "IL", text: "Israel" },
  { value: "IT", text: "Italien" },
  { value: "JM", text: "Jamaica" },
  { value: "JP", text: "Japan" },
  { value: "YE", text: "Yemen" },
  { value: "VG", text: "Britiske Jomfruøer" },
  { value: "VI", text: "Amerikanske Jomfruøer" },
  { value: "JO", text: "Jordan" },
  { value: "KH", text: "Cambodja" },
  { value: "CM", text: "Cameroun" },
  { value: "CV", text: "Kap Verde" },
  { value: "BQ", text: "Nederlandske Antiller" },
  { value: "KZ", text: "Kasakhstan" },
  { value: "KE", text: "Kenya" },
  { value: "CN", text: "Kina" },
  { value: "KG", text: "Kirgisistan" },
  { value: "KI", text: "Kiribati" },
  { value: "CC", text: "Cocosøerne" },
  { value: "KM", text: "Comorerne" },
  { value: "CG", text: "Congo" },
  { value: "CD", text: "Demokratiske Republik Congo" },
  { value: "HR", text: "Kroatien" },
  { value: "KW", text: "Kuwait" },
  { value: "CY", text: "Cypern" },
  { value: "LA", text: "Laos" },
  { value: "LV", text: "Letland" },
  { value: "LS", text: "Lesotho" },
  { value: "LB", text: "Libanon" },
  { value: "LR", text: "Liberia" },
  { value: "LY", text: "Libyen" },
  { value: "LI", text: "Liechtenstein" },
  { value: "LT", text: "Litauen" },
  { value: "LU", text: "Luxembourg" },
  { value: "MO", text: "Macao" },
  { value: "MG", text: "Madagaskar" },
  { value: "MK", text: "Makedonien" },
  { value: "MW", text: "Malawi" },
  { value: "MY", text: "Malaysia" },
  { value: "MV", text: "Maldiverne" },
  { value: "ML", text: "Mali" },
  { value: "MT", text: "Malta" },
  { value: "MA", text: "Marokko" },
  { value: "MH", text: "Marshalløerne" },
  { value: "MQ", text: "Martinique" },
  { value: "MR", text: "Mauretanien" },
  { value: "MU", text: "Mauritius" },
  { value: "YT", text: "Mayotte" },
  { value: "MX", text: "Mexico" },
  { value: "FM", text: "Mikronesien" },
  { value: "MD", text: "Moldova" },
  { value: "MC", text: "Monaco" },
  { value: "MN", text: "Mongoliet" },
  { value: "ME", text: "Montenegro" },
  { value: "MS", text: "Montserrat" },
  { value: "MZ", text: "Mozambique" },
  { value: "MM", text: "Burma" },
  { value: "NA", text: "Namibia" },
  { value: "NR", text: "Nauru" },
  { value: "NL", text: "Holland" },
  { value: "NP", text: "Nepal" },
  { value: "NZ", text: "New Zealand" },
  { value: "NI", text: "Nicaragua" },
  { value: "NE", text: "Niger" },
  { value: "NG", text: "Nigeria" },
  { value: "NU", text: "Niue" },
  { value: "KP", text: "Nordkorea" },
  { value: "MP", text: "Nordmarianerne" },
  { value: "NF", text: "Norfolk Island" },
  { value: "NO", text: "Norge" },
  { value: "NC", text: "Ny Kaledonien" },
  { value: "OM", text: "Oman" },
  { value: "PK", text: "Pakistan" },
  { value: "PW", text: "Palau" },
  { value: "PS", text: "Palæstina" },
  { value: "PA", text: "Panama" },
  { value: "PG", text: "Papua Ny Guinea" },
  { value: "PY", text: "Paraguay" },
  { value: "PE", text: "Peru" },
  { value: "PN", text: "Pitcairn" },
  { value: "PL", text: "Polen" },
  { value: "PT", text: "Portugal" },
  { value: "PR", text: "Puerto Rico" },
  { value: "QA", text: "Qatar" },
  { value: "RE", text: "Réunion" },
  { value: "RO", text: "Rumænien" },
  { value: "RU", text: "Rusland" },
  { value: "RW", text: "Rwanda" },
  { value: "SH", text: "Sankt Helena" },
  { value: "KN", text: "Saint Kitts og Nevis" },
  { value: "LC", text: "Saint Lucia" },
  { value: "PM", text: "Saint-Pierre og Miquelon" },
  { value: "VC", text: "Saint Vincent og Grenadinerne" },
  { value: "SB", text: "Salomonøerne" },
  { value: "WS", text: "Samoa" },
  { value: "SM", text: "San Marino" },
  { value: "ST", text: "São Tomé og Príncipe" },
  { value: "SA", text: "Saudi-Arabien" },
  { value: "SN", text: "Senegal" },
  { value: "CF", text: "Centralafrikanske Republik" },
  { value: "RS", text: "Serbien" },
  { value: "SC", text: "Seychellerne" },
  { value: "SL", text: "Sierra Leone" },
  { value: "SG", text: "Singapore" },
  { value: "SX", text: "Sint Maarten" },
  { value: "SK", text: "Slovakiet" },
  { value: "SI", text: "Slovenien" },
  { value: "SO", text: "Somalia" },
  { value: "ES", text: "Spanien" },
  { value: "LK", text: "Sri Lanka" },
  { value: "GB", text: "Storbritannien" },
  { value: "SD", text: "Sudan" },
  { value: "SR", text: "Surinam" },
  { value: "SJ", text: "Norge Svalbard og Jan Mayen" },
  { value: "SE", text: "Sverige" },
  { value: "CH", text: "Schweiz" },
  { value: "SZ", text: "Swaziland" },
  { value: "SY", text: "Syrien" },
  { value: "ZA", text: "Sydafrika" },
  { value: "GS", text: "South Georgia og South Sandwich Islands" },
  { value: "KR", text: "Sydkorea" },
  { value: "SS", text: "Sydsudan" },
  { value: "TW", text: "Republikken Kina Taiwan" },
  { value: "TJ", text: "Tadsjikistan" },
  { value: "TZ", text: "Tanzania" },
  { value: "TH", text: "Thailand" },
  { value: "TL", text: "Østtimor" },
  { value: "TG", text: "Togo" },
  { value: "TK", text: "Tokelau" },
  { value: "TO", text: "Tonga" },
  { value: "TT", text: "Trinidad og Tobago" },
  { value: "TD", text: "Tchad" },
  { value: "CZ", text: "Tjekkiet" },
  { value: "TN", text: "Tunesien" },
  { value: "TR", text: "Tyrkiet" },
  { value: "TM", text: "Turkmenistan" },
  { value: "TC", text: "Turks- og Caicosøerne" },
  { value: "DE", text: "Tyskland" },
  { value: "TV", text: "Tuvalu" },
  { value: "UG", text: "Uganda" },
  { value: "UA", text: "Ukraine" },
  { value: "HU", text: "Ungarn" },
  { value: "AE", text: "Forenede Arabiske Emirater" },
  { value: "UM", text: "USAs ydre småøer" },
  { value: "UY", text: "Uruguay" },
  { value: "UZ", text: "Usbekistan" },
  { value: "VU", text: "Vanuatu" },
  { value: "VA", text: "Vatikanstaten" },
  { value: "VE", text: "Venezuela" },
  { value: "EH", text: "Vestsahara" },
  { value: "VN", text: "Vietnam" },
  { value: "WF", text: "Wallis og Futuna" },
  { value: "ZM", text: "Zambia" },
  { value: "ZW", text: "Zimbabwe" },
  { value: "AT", text: "Østrig" },
  { value: "AX", text: "Ålandsøerne" },
];
