import { IonContent, IonHeader, IonPage } from "@ionic/react";
//import MainItemContainer from "../../components/MainItemContainer/MainItemContainer";
import TopContainer from "../../components/TopContainer/TopContainer";
import "./Done.css";
//import Button from "../../components/Button/Button";
//import { gql } from "@apollo/client";
//import { datastore } from "../../dataStore";

//const DELETE_AML = gql`
//  mutation deleteAML($input: DeleteAMLInput!) {
//    deleteAML(input: $input)
//  }
//`;

const Error: React.FC = () => {
  /*
  const [deleteAML, { loading, error, data }] = useMutation(DELETE_AML);

  if (loading) return <p></p>; //loading
  
  if (error) {
    console.log("Error:" + error.message);
    datastore.data.ErrorMessage = "Fejl ved nulstilling af AML flow." + error.message;
  }

  if (data) {
    window.location.href = '/?CustomerId=' + datastore.data.externalCustomerId + '&CaseId=' + datastore.data.externalCaseId + '&DocumentId=' + datastore.data.externalDocumentId;
  }

  const reset = () => {
    deleteAML({
      variables: {
        input: {
          publicAMLId: datastore.data.id,
        },
      },
    });
  };
  */

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent fullscreen>
        <TopContainer roadAndNumer="" cityAndZip="Tak for din besvarelse"></TopContainer>
      </IonContent>
    </IonPage>
  );
};

export default Error;
