import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";

const Pep: React.FC = () => {
  
  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <h2>En politisk eksponeret person er:</h2>
          <ul>
              <li>
              Statschef, regeringschef, minister og assisterende minister (ministre og departementschefer)
              </li>
              <li>
              Parlamentsmedlem eller medlem af tilsvarende lovgivende organer. (medlemmer af Folketinget og danske medlemmer af Europa Parlamentet)
              </li>
              <li>
              Medlem af et politisk partis styrende organer.
              </li>
              <li>
              Højesteretsdommer, medlem af forfatningsdomstole og andre højtstående retsinstanser, hvis afgørelser kun er genstand for yderligere prøvelse under ekstraordinære omstændigheder (højesteretsdommere og danske dommere ved internationale domstole)
              </li>
              <li>
              Medlem af revisionsretter og øverste ledelsesorganer for centralbanker. (direktionen for Danmarks Nationalbank, danske statsrevisorer og danske medlemmer af den Europæiske Revisionsret).
              </li>
              <li>
              Ambassadør, chargé d’affaires og højtstående officer i de væbnede styrker (forsvarschefer, viceforsvarschefer, værnschefer, ambassadører)
              </li>
              <li>
              Medlem af statsejede virksomheders administrative, ledende eller kontrollerende organer.( bestyrelsesmedlemmer eller direktører i selskaber, hvor staten ejer 50 % eller mere eller på anden måde har reel kontrol over selskabet)
              </li>
              <li>
              Direktør, vicedirektør, bestyrelsesmedlem eller person med tilsvarende hverv i internationale organisationer. (personer der er indstillet, udpeget eller ansat af regeringen, et ministerium eller en minister i en international organisation) Finanstilsynet fører en oversigt over danske PEP på tilsynets hjemmeside, https://www.finanstilsynet.dk/Tal-og-Fakta/PEP-liste
              </li>
          </ul>

          
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default Pep;
