import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";

const PepCloseRelation: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <BackButton />
        <PageContainer>
          <h2>En nærtstående til en politisk eksponeret person er:</h2>
          <ul>
            <li>Ægtefælle</li>
            <li>Registreret partner</li>
            <li>Samlever</li>
            <li>Børn og disses ægtefæller, registrerede partnere eller samlevere</li>
            <li>Forældre</li>
          </ul>
        </PageContainer>
      </IonContent>
    </IonPage>
  );
};
export default PepCloseRelation;
